import React, { FC, useEffect, useRef, useState } from 'react';
import { toast } from '../../utils/notification';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent} from '@syncfusion/ej2-react-buttons';
import { Header, Modal } from 'semantic-ui-react';
import { setCookie } from '../../utils/cookies';
import './styles.css';
import request from '../../request/request';

const LoginTokenDialog: FC<any> = ({ dialogVisible, username, password, resetState, setDialogVisible, version, authenticationMode }) => {
  const [token, setToken] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const textBoxRef = useRef(null);

  useEffect(() => {
    if (dialogVisible) {
      localStorage.setItem('2k-dialog', JSON.stringify(true));
      setTimeout(() => { textBoxRef.current.focusIn();}, 300);
    }
  }, [dialogVisible]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (token === '' || username === '' || password === '')
      return null;

    try {
      const extended = JSON.parse(localStorage.getItem('extended'));
      const response = await request<Request.Login, any>(false, 'POST', 'public/auth/signin', { extended, authenticationMode: authenticationMode, username, password, version, token: token });
 
      Object.keys(response.data).forEach(key => localStorage.setItem(key, JSON.stringify(response.data[key])));
      if (rememberMe) {
        setCookie('sessionCookie', 'cookieVlaue', 3);
      }
      localStorage.removeItem('2k-dialog');
      window.location.href = '/app';
      resetState(e);
      setToken('');
      setRememberMe(false);
        
    } catch (error) {
      if (error.response) {
        await request(true, 'POST', 'public/user/logout' );
        toast.error('', error.response.data.message);
      }
    }
  };



  return (
    <Modal
      style={{ width: '300px' }}
      open={dialogVisible}
    >
      <Modal.Content
        className="dialog-content"
      >
        <Header
          as="h2"
          textAlign="center"
          className="dialog-header"
        >
                    Authentication
        </Header>
        <form
          action=""
          onSubmit={(e) => handleSubmit(e)}
        >
          {/* <CheckBoxComponent
                        label='remember me'
                        checked={rememberMe}
                        onChange={(args) => setRememberMe(args.target.checked)}
                    /> */}
          <TextBoxComponent
            ref={textBoxRef}
            type="text"
            name="token"
            className='login-form-input'
            value={token}
            change={(e) => { setToken(e.value); }}
            placeholder="OTP-Secret"
            floatLabelType="Auto"
          />
          <div className="token-dialog-buttons">
            <ButtonComponent
              type="Submit"
              cssClass='e-custom'
              style={{ textTransform: 'none' }}
              content={'Confirm'}
            />
            <ButtonComponent
              cssClass='e-custom'
              style={{ textTransform: 'none', marginLeft: '10px' }}
              content={'Cancel'}
              onClick={async() => {
                localStorage.removeItem('2k-dialog');
                await request(true, 'POST', 'public/user/logout' );
                setDialogVisible(false);
                setToken('');
              }}
            />
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default LoginTokenDialog;