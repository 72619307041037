import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  Zoom,
  Crosshair,
  WaterfallSeries
} from '@syncfusion/ej2-react-charts';
import { WidgetContainerStyled, WidgetContentStyled } from '../styles';
import { WidgetProvider } from '../WidgetContext';
import MenuBar from '../../components/MenuBar/MenuBar';
import { ChartContainerStyled } from './styles';
import { runDynamicQuery } from '../../api/query/run.dynamicQuery';
import { toast } from '../../utils/notification';
import { useSelector } from 'react-redux';

const ChartWidget = ({ navData, actionsState }) => {
  const chartInstance = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [currentWorkspaceUuid, seCurrentWorkspaceUuid] = useState(null);
  const workspaces = useSelector((state: any) => state.workspaces.instances);

  const widgetContext: any = {
    widgetData: navData.widgetData,
    viewInstance: chartInstance.current,
    actionsState: actionsState,
    currentWorkspaceUuid: currentWorkspaceUuid
  };

  useEffect(() => {
    const currentWorkspace = workspaces?.find((w) => w.isActive);
    seCurrentWorkspaceUuid(currentWorkspace.uuid);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {      
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
        setChartData(response);
      } catch (error) {
        toast.error('Error fetching Gantt data:', error.message);
      }
    };
    fetchData();
  }, [navData.widgetData.dataID, navData.widgetData.timestamp]);


  if(!chartData) return;

  return (

    <WidgetContainerStyled>
      <WidgetProvider value={widgetContext}>
        {navData.widgetData.menu && (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
        )}
        <WidgetContentStyled>
          <ChartContainerStyled>
            <ChartComponent
              ref={(g) => (chartInstance.current = g)}
              height='100%'
              id={navData.widgetData.dataID}
              primaryXAxis={navData.widgetData.primaryxAxis}
              primaryYAxis={navData.widgetData.primaryyAxis}
              tooltip={navData.widgetData.tooltip}
              legendSettings={navData.widgetData.legendSettings}
            >
              <Inject
                services={[WaterfallSeries, Category, Tooltip, Zoom, Crosshair, Legend, DataLabel]}
              />
              <SeriesCollectionDirective>
                <SeriesDirective
                  dataSource={chartData}
                  xName="name"
                  yName="value"
                  //   name="test"
                  type={navData.widgetData.type}
                  sumIndexes={navData.widgetData.sumIndexes}
                  summaryFillColor={navData.widgetData.summaryFillColor}
                  marker={navData.widgetData.marker}
                  connector={navData.widgetData.connector}
                  columnWidth={0.9}
                  fill={navData.widgetData.fill}
                  negativeFillColor={navData.widgetData.negativeFillColor}
                />
              </SeriesCollectionDirective>
            </ChartComponent>
          </ChartContainerStyled>
        </WidgetContentStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

ChartWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default ChartWidget;