/* eslint-disable no-case-declarations */
import React, { useState, useRef, useEffect } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent, ContextMenu, Filter, Inject, Page, ExcelExport, Resize, Toolbar, InfiniteScroll, Sort, Aggregate } from '@syncfusion/ej2-react-grids';
import { setGridData } from '../services/setGridData';
import { WidgetProvider } from '../../WidgetContext';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { setCustomContextMenu } from '../services/setContextMenu';
import { getValue } from '@syncfusion/ej2-base';
// import { setDataCounter } from '../services/dataCounter/setDataCounter';
import { contextMenuClick } from '../services/contextMenuClick';
import { linkElements } from './events/linkElements';
import { rowSelecting } from './events/rowSelecting';
import { useTranslation } from 'react-i18next';
import { getSettingsLink } from './settings';
import { insertFilterValues, setFilterValues } from '../services/setFilterValues';
import { setKeyDownRemoveFilter } from '../services/setKeydownEvent';
import PropTypes from 'prop-types';
import { WidgetContainerStyled, WidgetContentStyled, WidgetLabelStyled } from '../../styles';
import { setFilterDateTimeTemplate } from '../services/setDateTimeFilter';
import { getAggregations } from '../services/aggregations';
import { GridContainerStyled } from '../styles';
import setClassNames from '../services/setClassNames';
import ColumnImageComponent from '../components/ColumnsImage';
import { insertSortValues, setSortValues } from '../services/setSortValues';
// import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import '../stylesTooltip.css';

const GridWidgetLink = ({ navData, actionsState }) => {
  const [gridData, setGridDataState] = useState(null);
  const [gridEditData, setGridEditData] = useState([]);
  // const [dataCounterString, setDataCounterString] = useState('');
  const { t } = useTranslation();
  let selIndex = [];

  const timeFormat = JSON.parse(localStorage.getItem('timeFormat'));
  const dateFormat = JSON.parse(localStorage.getItem('dateFormat'));

  const gridLinkInstance = useRef(null);

  const widgetContext = {
    instance: gridLinkInstance.current,
    gridEditData: gridEditData,
    widgetData: navData.widgetData,
    actionsState: actionsState
  };

  useEffect(() => {
    const handleKeyDown = (event) => setKeyDownRemoveFilter(event, gridLinkInstance.current);
    setGridDataState(null);
    setGridData(setGridDataState, navData, actionsState);

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      setGridDataState(null);
      setGridEditData([]);
      // setDataCounterString('');
      window.removeEventListener('keydown', (event) => setKeyDownRemoveFilter(event, gridLinkInstance.current));
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);


  const created = () => {
    setGridEditData(gridData.filter(element => element.selected));
    insertFilterValues(gridLinkInstance.current, navData);
    insertSortValues(gridLinkInstance.current, navData); 
    window.addEventListener('keydown', (event) => setKeyDownRemoveFilter(event, gridLinkInstance.current));
  };

  const dataBound = () => {
    if (gridLinkInstance.current && selIndex.length) {
      gridLinkInstance.current.selectRows(selIndex);
      selIndex = [];
    }
  };

  const rowDataBound = (args) => {
    setClassNames(args);
    if (getValue('selected', args.data) === true) {
      selIndex.push(parseInt(args.row.getAttribute('data-rowindex'), 0));
    }
  };

  const actionComplete = async (args) => {
    if (actionsState && navData) {
      if(['filter', 'filtering', 'clearFilter'].includes(args.requestType)){
        await setFilterValues(args, navData.widgetData.key);
      }
      if(args.requestType === 'sorting'){  
        await setSortValues(args, navData.widgetData.key);
      }
    }
    // await setDataCounter(args, { setState: setDataCounterString, t });
  };

  // const setTooltip = args => {
  //   const tooltip = (document.getElementById('tooltip-header') as any).ej2_instances[0];
  //   tooltip.content = args.target.closest('span').innerText;
  // };


  if (!navData?.widgetData || !gridData || !timeFormat || !dateFormat) {
    return null;
  }

  const settings = getSettingsLink(navData);
  const aggregations = getAggregations(navData);

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={widgetContext}>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
        ) : null}
        {navData.widgetData.label !== '' ? <WidgetLabelStyled>{t(navData.widgetData.label)} </WidgetLabelStyled> : ''}
        <WidgetContentStyled>
          <GridContainerStyled css={navData.widgetData.style} height={navData.widgetData.height}>
            {/* <TooltipComponent id='tooltip-header' target='.e-headertext' cssClass='grid-header-tooltip' beforeRender={setTooltip}> */}
            <GridComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              ref={(g) => (gridLinkInstance.current = g)}
              id={`grid-${navData.widgetData.key}`}
              key={`grid-${navData.widgetData.key}`}
              height={navData.widgetData.height ? navData.widgetData.height : '100%'}
              rowHeight={28}
              dataSource={gridData}
              className={`grid-widget${navData.widgetData.type === 'link' && navData.widgetData.rowSelection === 'single' ? ' e-link-grid' : ''}`}
              allowExcelExport={true}
              allowFiltering={true}
              allowResizing={true}
              allowSelection={true}
              allowSorting={true}
              allowPaging={navData.widgetData.allowPaging}
              enableInfiniteScrolling={!navData?.widgetData?.allowPaging}
              filterSettings={settings.filterSettingsMenu}
              contextMenuItems={setCustomContextMenu(navData)}
              pageSettings={navData?.widgetData?.allowPaging ? settings.pageSettings : settings.pageSettingsInfinite}
              created={created}
              rowSelected={(args) => linkElements(gridData, setGridEditData, navData, args, true, gridLinkInstance.current)}
              rowDeselected={(args) => linkElements(gridData, setGridEditData, navData, args, false, gridLinkInstance.current)}
              rowSelecting={(args) => rowSelecting(gridData, setGridEditData, navData, args, gridLinkInstance.current)}
              contextMenuClick={(args) => contextMenuClick(args, gridLinkInstance.current, navData ? setCustomContextMenu(navData) : null)}
              dataBound={dataBound}
              rowDataBound={rowDataBound}
              actionComplete={actionComplete}
              aggregates={aggregations}
            >
              <ColumnsDirective>
                <ColumnDirective
                  type='checkbox'
                  width='24px'
                  customAttributes={settings.customAttributes}
                  allowResizing={false}
                />
                {navData.widgetData.columns.map((column) => {
                  switch (column.type) {
                    case 'boolean':
                      return null;
                    case 'code':
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                          textAlign={'Center'}
                        />
                      );
                    case 'datetime':
                    case 'date':
                      const filterTemplateDateTime = setFilterDateTimeTemplate({ dateFormat, timeFormat }, column, gridEditData, setGridEditData);
                      const type = column.type === 'date' ? 'date' : 'dateTime';
                      const format = `${dateFormat}${column.type === 'datetime' ? ` ${timeFormat}` : ''}`;

                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          field={column.name}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          filterBarTemplate={filterTemplateDateTime}
                          visible={!column.hide}
                          disableHtmlEncode={false}
                          isPrimaryKey={column.name === 'id'}
                          type={type}
                          format={{type: type, format: format}}
                        />
                      );
                    case 'binary':
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                          template={(row) => <ColumnImageComponent row={row} />}
                          textAlign={'Center'}
                        />
                      );
                    default:
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          field={column.name}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          filter={settings.filterMenu}
                          visible={!column.hide}
                          disableHtmlEncode={false}
                          isPrimaryKey={column.name === 'id'}
                          type={'string'}
                        />
                      );
                  }
                })}
              </ColumnsDirective>
              <Inject services={[ContextMenu, Filter, Page, ExcelExport, Resize, Toolbar, InfiniteScroll, Sort, Aggregate]} />
            </GridComponent>
            {/* </TooltipComponent> */}
          </GridContainerStyled>
        </WidgetContentStyled>
        {/* {!navData?.widgetData?.allowPaging && (
          <DataCounter
            counter={dataCounterString}
          />
        )} */}
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

GridWidgetLink.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GridWidgetLink;
