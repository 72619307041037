import React from 'react';
import PropTypes from 'prop-types';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

const PolicyDialog = ({ dialogVisiblePolicy, setDialogVisiblePolicy, dialogContentPolicy }) => {
  if (!dialogContentPolicy) return null;

  return (
    <DialogComponent
      visible={dialogVisiblePolicy}
      isModal={true}
      height={'calc(100% - 100px)'}
      width={'80%'}
      enableResize={false}
      resizeHandles={[]}
      allowDragging={false}
      showCloseIcon={true}
      close={() => setDialogVisiblePolicy(false)}
      content={dialogContentPolicy}
    >
    </DialogComponent>
  );
};

PolicyDialog.propTypes = {
  setDialogVisiblePolicy: PropTypes.func.isRequired,
  dialogVisiblePolicy: PropTypes.bool.isRequired,
  dialogContentPolicy: PropTypes.string
};

export default PolicyDialog;
