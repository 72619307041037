/* eslint-disable @typescript-eslint/no-unused-vars */
export const mouseoverNode = async(cy: any) => {
  return await cy.on('mouseover', 'node', async function (e) {
    const node = e.target;
      
    cy.container().style.cursor = 'pointer';
  
    node
      .union(node.incomers)
      .union(node.outgoers)
      .addClass('hover');
  });
};
  
export const mouseoverEdge = async(cy: any) => {
  return await cy.on('mouseover', 'edge', async function (e) {
    const edge = e.target;
      
    cy.container().style.cursor = 'pointer';
  
    edge
      .union(edge.incomers)
      .union(edge.outgoers)
      .addClass('hover');
  });
};

export const mouseoutNode = async(cy: any) => {
  return await cy.on('mouseout', 'node', async function (e) {
    const node = e.target;
  
    cy.container().style.cursor = 'default';
  
    node
      .union(node.incomers)
      .union(node.outgoers)
      .removeClass('hover');
  });
};
  
export const mouseoutEdge = async(cy: any) => {
  return await cy.on('mouseout', 'edge', async function (e) {
    const edge = e.target;
      
    cy.container().style.cursor = 'default';
  
    edge
      .union(edge.incomers)
      .union(edge.outgoers)
      .removeClass('hover');
  });
};
  

export const mouseDown = async (cy: any, setSelectedElements: any) => {
  return await cy.on('mousedown', async (e) => {
    
    if(e.originalEvent.ctrlKey) return null;

    localStorage.setItem('selectedElements', JSON.stringify([]));
    // setSelectedElements([]);
    cy.nodes('.selected').removeClass('selected');
    cy.edges('.selected').removeClass('selected');
  });
};

export const mouseUp = (cy: any) => {
  cy.on('mouseup', (e) => {
    const targetData = e.target.data();
    cy.container().style.cursor = (targetData && Object.keys(targetData).length !== 0) ? 'pointer' : 'default';
  });
};
    
  
  