import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { cookieDeclineToast } from './cookie.Decline.toast';
import { logout } from '../../../api/functions/func/function.logout';
import DOMPurify from 'dompurify';

const CookieConsentComponent = ({ 
  dialogVisibleCookies, 
  setDialogVisibleCookies, 
  dialogContentCookies,
  type
}) => {


  const handleDecline = () => {
    localStorage.setItem('cookieAccepted', JSON.stringify(false));
    setDialogVisibleCookies(false);
    cookieDeclineToast();
    if(type === 'system'){
      setTimeout(logout, 4000);
    }
  };

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', JSON.stringify(true));
    setDialogVisibleCookies(false);
  };

  const buttons = [
    {
      label: navigator.language === 'de-DE' ? 'Ablehnen' : 'Decline',
      onClick: handleDecline,
      key: 'decline'
    },
    {
      label: navigator.language === 'de-DE' ? 'Alles akzeptieren' : 'Accept all',
      onClick: handleAccept,
      key: 'accept'
    }
  ];

  if (!dialogContentCookies) return null;

  return (
    <Modal
      open={dialogVisibleCookies}
      closeOnDimmerClick={false}
      centered={true}
      style={{ 
        overflowY: 'scroll', 
        overflowX: 'hidden',
        height: '50%',
        width: '30%'
      }}
    >
      <Modal.Header>
        <div
          className={'e-icons e-close label-icon modal-close-icon'}
          onClick={() => { setDialogVisibleCookies(false); }}
        />
      </Modal.Header>
      <Modal.Content>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dialogContentCookies) }} />
      </Modal.Content>
      <Modal.Actions style={{ backgroundColor: 'white' }}>
        {buttons.map(button => (
          <button 
            key={button.key}
            className='e-control e-btn e-lib e-custom'
            onClick={button.onClick}
            style={{ marginRight: '10px' }}
          >
            {button.label}
          </button>
        ))}
      </Modal.Actions>
    </Modal>
  );
};

CookieConsentComponent.propTypes = {
  dialogVisibleCookies: PropTypes.bool.isRequired,
  setDialogVisibleCookies: PropTypes.func.isRequired,
  dialogContentCookies: PropTypes.string,
  type: PropTypes.string
};

export default CookieConsentComponent;
