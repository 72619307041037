import { toast } from '../../../utils/notification';
import { handleError } from './functions/handleError';
import { handleNewPostsCount } from './functions/handleNewPostsCount';
import { handleOpen } from './functions/handleOpen';
import { handleSendPost } from './functions/handleSendPost';

let eventSourceUrl;
export let eventSource;

export const setEventSourceHeartBeat = async () => {
  try {
    eventSourceUrl = `${process.env.REACT_APP_BACKEND_URL}public/services/heartbeat`;
    eventSource = new EventSource(eventSourceUrl, { withCredentials: true });

    eventSource.onopen = handleOpen;
    eventSource.addEventListener('sendPost', handleSendPost);
    eventSource.addEventListener('newPostsCount', handleNewPostsCount);
    eventSource.addEventListener('orderUpdate', handleNewPostsCount);
    eventSource.onerror = handleError;
  } catch (error) {
    toast.error('Error setting up EventSource', error.message);
    setTimeout(setEventSourceHeartBeat, 5000);
  }
};

export const closeEventSourceHeartbeat = async () => eventSource.close();
